import styled from 'styled-components';

export const ChartWrapper = styled.div`
  background: rgba(4, 19, 56, 1);
  padding: 24px;
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(43, 106, 230, 1);
  overflow-x: auto;

  .ant-radio-group{
    .ant-radio-button-wrapper {
      background: rgba(40, 44, 56, 1);
      color: rgba(117, 123, 140, 1);
      border-color: rgba(117, 123, 140, 1);
      font-size: 13px;
      font-weight: 500;
    }

    .ant-radio-button-wrapper:first-child {
      border-radius: 6px 0px 0px 6px;
      border-color: rgba(117, 123, 140, 1);
    }
    .ant-radio-button-wrapper:last-child {
      border-radius: 0 6px 6px 0;
    }
    .ant-radio-button-wrapper-checked {
      background: rgba(21, 109, 242, 1);
      color: #ffffff;
      border-color: rgba(117, 123, 140, 1);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: rgba(21, 109, 242, 1);
      color: rgba(117, 123, 140, 1);
      border-color: rgba(117, 123, 140, 1);
    }
    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper {
      border-color: rgba(117, 123, 140, 1);
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: rgba(117, 123, 140, 1);
    }
  }
`;

export const Time = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  font-family: Noto Sans JP;
  flex-wrap: wrap;
`;

