import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border: 1px solid rgba(43, 106, 230, 0.5);
  padding: 24px;

  @media (max-width: 1280px) {
    padding: 16px;
  }

  .breadcrumb {
    span {
      font-size: 18px;
      color: #8a8aa0;
      font-weight: 400;

      &.active {
        color: #fff;
      }

      &.click {
        cursor: pointer;
      }
    }
  }

  .title {
    font-size: 24px;
    margin-top: 5px;
  }

  .group-btn {
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .group-filter{
    margin-left: 1.3%;
  }

  .custom-frame {
    width: 100%;
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
  }

  .position-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    mix-blend-mode: color-dodge;

    @media (max-width: 1280px) {
      display: none;
    }

    .frame {
      width: 100%;
      height: 100%;
    }

    .light-dot {
      position: absolute;
      z-index: 0;
      width: 50px;
      height: 50px;

      &.light-dot-1 {
        top: -5px;
        right: -6px;
      }

      &.light-dot-2 {
        top: -23px;
        right: 15px;
      }

      &.light-dot-3 {
        bottom: -23px;
        left: -5px;
      }

      &.light-dot-4 {
        bottom: -23px;
        right: -6px;
      }
    }
  }

  .custom-frame-div {
    position: absolute;
    width: calc(100% - 100px);
    padding-top: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const TableContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 16px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  z-index: 9;

  @media (max-width: 1280px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Total = styled.div`
  margin-top: 10px;
  //margin-bottom: 50px;
  display: flex;
  //justify-content: center;
  gap: 20px;

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  .box-table {
    display: flex;
    // height: 466px;
    max-height: 180px;
    overflow: auto;
    // justify-content: center;
    //border: 1px solid #156df280;
    &.h-337 {
      // height: 337px;
      max-height: 178px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #2b6ae680;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #2b6ae680;
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .text-center {
    text-align: center;
  }
  .list-box {
    list-style-type: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0px;

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 100;
      font-size:14px;

      .box {
        width: 14px;
        height: 14px;
        border: 1px solid #5fa9ff;

        &.sold {
          background-color: #ffba43;
          border: 1px solid #ffe2af;
        }

        &.bought {
          background-color: #66db66;
          border: 1px solid #abffa9;
        }

        &.selected {
          background-color: #f759ab;
          border: 1px solid #ffadd2;
        }
      }
    }
  }

  .total-time {
    padding: 4px 16px;
    background: rgba(21, 109, 242, 0.21);
    border: 1px solid #156df2;
    display: flex;
    gap: 4px;
    align-items: center;
    height: 34px;

    .time-title {
      font-size: 14px;
      font-weight: 400;
    }
    .hour {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .total-amount {
    display: flex;
    flex-direction: column;

    @media (max-width: 1280px) {
      width: 100% !important;
    }

    .color-gray{
      color: #A8AEBA;
    }
    .amount-item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      gap: 16px;

      .amount-title {
        font-size: 14px;
        font-weight: 400;
        width: 200px;
      }
      .amount-value {
        font-size: 14px;
        font-weight: 400;
        flex: 1;
        white-space: nowrap;       
        overflow: hidden;    
        text-overflow: ellipsis; 
        width: 100%; 
      }
    }

    .w-160{
      width: 160px;
    }
    .justify-space{
      justify-content: space-between;
    }
    .yellow {
      font-size: 18px;
      font-weight: 700;
      color: #fda000;
    }
  }

  .total.total-amount {
    .amount-value {
      text-align: end;
    }
  }

  .padding{
    padding: 4px 16px;
  }

  .border{
    border: 1px solid #156df2;
  }
  
  .height-auto{
    height: fit-content;
  }

  .gap-10{
    gap: 10px
  }

  .gap-5{
    gap: 5px
  }

  .detail-table {
    height: 150px;
    overflow: auto;
  }

  .w-600{
    width: 600px;
  }

  table.scroll {
    width: 100%;
    border-collapse: collapse;

    @media (max-width: 576px) {
      width: max-content;
    }

    thead th {
      position: sticky;
      top: 0px;
      background-color: #051847;
      z-index: 10;
      font-weight: 400;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 2px;
      height: 9px;
      position: absolute;
      z-index: 100;
      top:0;
      right: 0;
      display: block;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: unset;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #156df2;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #156df2;
    }

    .first-col, .first-col ~ th {
      position: sticky;
      left: 1px;
    }

    thead {
      position: sticky;
      top: 0px;
      background-color: #051847;
      z-index: 9;
    }

    td, th {
      border: 1px solid #156DF2;
      padding: 3px 8px;
      text-align: left;
    }

    td {
      height: 30px;
      font-size: 14px;
    }

    thead {
      font-size: 14px;
      font-weight: 400;
    }

    thead::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-bottom: 0.5px solid #156DF2;
      border-top: 0.5px solid #156DF2;
      z-index: 9;
      margin-top: -1px;
    }

    thead {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;