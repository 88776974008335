import Aos from "aos";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";

import Confirmable from "../../components/confirmable";
import MaskLoading from "../../components/mask-loading";
import { useWeb3Auth } from "../../contexts/web3auth";
import ScrollToTop from "../../utils/scrollToTop";
import ManagementHeader from "./Header";
import { Content, ManagementWrapper } from "./styled";
import SideBar from "./SideBar";
import Storage from "./../../utils/storage";
import { LOCAL_STORAGE_KEY } from "../../constants";

const ManagementLayout = () => {
  const { accessToken, currentAccount, userInfo } = useWeb3Auth();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    if (!Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN)) {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManagementWrapper>
      <ManagementHeader
        accessToken={accessToken}
        publicAddress={currentAccount}
        userInfo={userInfo}
        showDrawer={showDrawer}
        isShowMenu={true}
      />
      <div className="dp-flex">
        <SideBar onClose={onClose} open={open} />
        <Content>
          <ScrollToTop>
            <Outlet />
          </ScrollToTop>
        </Content>
      </div>

      <MaskLoading ref={(ref) => MaskLoading.setInstance(ref)} />
      <Confirmable ref={(ref) => Confirmable.setInstance(ref)} />
    </ManagementWrapper>
  );
};
export default ManagementLayout;
