import styled from 'styled-components';

export const NewAdvertiseWrapper = styled.div`
  .breadcrumb {
    span {
      font-size: 18px;
      color: #8a8aa0;
      font-weight: 400;

      &.active {
        color: #fff;
      }

      &.click {
        cursor: pointer;
      }
    }
  }

  .title {
    font-size: 24px;
    margin-top: 5px;
  }

  .group-btn {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: 400px) {
      .btn-image {
        img {
          width: 90%;
        }
      }
    }
  }

  .group-filter{
    margin-left: 1.3%;
  }

  .custom-frame {
    width: 100%;
    height: 525px;
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-bottom: 20px;
    position: relative;

    @media (max-width: 1280px) {
      height: 100%;
    }
  }

  .position-frame {
    position: absolute;
    width: 100%;
    height: 100%;

    @media (max-width: 1280px) {
      display: none;
    }

    .frame {
      width: 100%;
      height: 100%;
    }

    .light-dot {
      position: absolute;
      z-index: 10;
      width: 50px;
      height: 50px;
      mix-blend-mode: color-dodge;

      &.light-dot-1 {
        top: -5px;
        right: -6px;
      }

      &.light-dot-2 {
        top: -23px;
        right: 15px;
      }

      &.light-dot-3 {
        bottom: -23px;
        left: -5px;
      }
    }
  }

  .custom-frame-div {
    position: absolute;
    width: calc(100% - 100px);
    padding-top: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1280px) {
      position: unset;
      width: calc(100% - 20px);
    }
  }
`;
