import styled from 'styled-components';
import { Images } from '../../theme';

export const AdvertiseWrapper = styled.div`
  border: 1px solid rgba(43, 106, 230, 0.5);
  padding: 10px;
  .advertise-tab {
    margin: 20px 0;
    margin-left: 20px;

    .ant-tabs-nav-wrap {
      border-bottom: 1px solid #051847;
    }

    .ant-tabs-nav-list {
      display: flex;
    }

    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        background-image: url(${Images.TAB_UN_ACTIVE});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 130px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #a8aeba;
        font-size: 16px;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        background-image: url(${Images.TAB_ACTIVE});
        color: #fff;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-image {
      img {
        width: 150px;
      }

      @media (max-width: 576px) {
        width: 150px;
        text-align: center;
      }
    }

    h2 {
      margin-left: 20px;
    }
  }

  .advertise-column {
    display: flex;
    flex: 1;
  }
`;
