import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

import { Images } from "../../../theme";
import { SidebarMobileWrapper, SidebarWrapper } from "./styled";
import Misc from "../../../utils/misc";
import { ArrowLeftOutlined } from "@ant-design/icons";

const SideBar = ({ onClose, open }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(true);

  const itemLink = [
    {
      name: "Request Management",
      to: "/management",
    },
    {
      name: "Advertise",
      to: "/advertise",
    },
    {
      name: "Statistics",
      to: "/statistics",
    },
  ];

  const toggleBtn = () => {
    setShowSubMenu(!showSubMenu);
  };

  const menu = (onClick = () => {}) => {
    return (
      <SidebarWrapper className="menu">
        <div className="sub-menu" onClick={toggleBtn}>
          <img src={Images.ICON_ADV} alt="icon-adv" />
          <span>Advertisement</span>
          {!showSubMenu ? (
            <img
              src={Images.ICON_DROPDOWN}
              className="icon-down"
              alt="icon-down"
            />
          ) : (
            <img
              src={Images.ICON_DROPDOWN}
              className="icon-down up"
              alt="icon-down"
            />
          )}
        </div>
        {showSubMenu && (
          <ul>
            {itemLink.map((item, index) => {
              return (
                <div
                  className={`dp-flex ${
                    pathname.includes(item.to) ? "activated" : ""
                  }`}
                  key={index}
                >
                  <div
                    className={`${pathname.includes(item.to) ? "border" : ""}`}
                  ></div>
                  <li key={index}>
                    <Link
                      relative="path"
                      className={pathname === item.to ? "disabled" : ""}
                      to={item?.to}
                      onClick={onClick}
                    >
                      {item.name}
                    </Link>
                  </li>
                </div>
              );
            })}
          </ul>
        )}
      </SidebarWrapper>
    );
  };

  const menuMobile = () => {
    return (
      <SidebarMobileWrapper
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"left"}
        title={
          <div className="header">
            <div onClick={() => navigate("/")} className="logo">
              <img src={Images.LOGO_HEADER} alt="Logo MCC" />
            </div>
            <div className="icon-close">
              <ArrowLeftOutlined onClick={onClose} color="#ffffff" />
            </div>
          </div>
        }
      >
        {menu(onClose)}
      </SidebarMobileWrapper>
    );
  };

  return <>{Misc.isMobile ? menuMobile() : menu()}</>;
};

export default SideBar;
