import React, { useEffect, useMemo } from 'react';
import { Radio } from 'antd';
import ReactECharts from 'echarts-for-react';



import { ChartWrapper, Time } from './styled';

const Chart = ({ option, title, onChangeTab, value }) => {
  const onChange = (e) => {
    const { target } = e;
    onChangeTab(target.value);
  }
  return (
    <ChartWrapper>
      <Time>
        <span>
          {title}
        </span>
        <Radio.Group onChange={onChange} value={value} buttonStyle="solid">
          <Radio.Button value="DAILY">Daily</Radio.Button>
          <Radio.Button value="WEEKLY">Weekly</Radio.Button>
          <Radio.Button value="MONTHLY">Monthly</Radio.Button>
        </Radio.Group>
      </Time>

      <ReactECharts option={{ ...option }} style={{ minWidth: 600 }} />
    </ChartWrapper>
  );
};

export default Chart;
