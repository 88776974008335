import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd";

import { useAppDispatch, useAppSelector } from "../../stores";
import { getHistoriesAdvertiseDetailAction } from "../../stores/screens/advertise/advertise.action";
import { Images } from "../../theme";
import {
  feeAmount,
  fixAdditionErrorJavascript,
  formattofixed,
  oneDecimalPlace,
  sliceDecimalTwo,
  substract
} from "../../utils/number";
import { Wrapper, Total, TableContent } from "./styled";

import AdvertiseTable from "./components/table/index";
import moment from "moment";

const AdvertiseDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, transactionId } = useParams();

  const { advertiseHistoryDetail, isLoading } = useAppSelector(
    (state) => state.advertise
  );
  const { data, fee_percent, advertisement_name, discount_percent } =
    advertiseHistoryDetail;
  const [dataTable, setDataTable] = useState([]);

  const daySelected =
    moment(data[0]?.end_time).diff(moment(data[0]?.start_time), "day") + 1;

  useEffect(() => {
    if (id && transactionId) {
      dispatch(getHistoriesAdvertiseDetailAction({ id, transactionId }));
    }
  }, [id, transactionId]);

  const amount = useMemo(() => {
    if (data && data.length) {
      return data
        .map((m) => ({
          price: m.prices.reduce(
            (accumulator, currentValue) => accumulator + currentValue.price,
            0
          ),
          times: moment(m.end_time).diff(moment(m.start_time), "day") + 1,
        }))
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue.price * currentValue.times;
        }, 0);
    }
    return 0;
  }, [data]);

  const discount = useMemo(() => {
    if (discount_percent) return feeAmount(amount, discount_percent);
    return 0;
  }, [amount, discount_percent]);

  const fee = useMemo(() => {
    if (fee_percent) return feeAmount(substract(amount, discount), fee_percent);
    return 0;
  }, [amount, fee_percent, discount]);

  // const hours = useMemo(() => {
  //   if (data && data.length) {
  //     return data
  //       .map(
  //         (m) =>
  //           m.prices.length *
  //           (moment(m.end_time).diff(moment(m.start_time), 'day') + 1)
  //       )
  //       .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  //   }
  //   return 0;
  // }, [data]);

  useEffect(() => {
    const createTableData = () => {
      if (!data[0]?.prices || data[0]?.prices?.length === 0) {
        return [];
      }

      const planPrices = data.reduce((acc, curr) => {
        const prices = Array.isArray(curr.prices) ? curr.prices : [curr.prices];
        const extendedPrices = prices.map((price) => ({
          hour: price?.hour,
          price: price?.price,
          building: curr?.building,
          frame: curr?.category,
        }));
        return [...acc, ...extendedPrices];
      }, []);

      const tableData = [];
      const dayData = planPrices
        .sort((a, b) => a.hour - b.hour)
        .map((item) => {
          const nextHour = (Number(item.hour) + 1) % 24;
          return {
            time_slot: `${String(item.hour).padStart(2, "0")}-${String(
              nextHour
            ).padStart(2, "0")}`,
            quantity: daySelected,
            price: sliceDecimalTwo(item.price),
            amount: sliceDecimalTwo(daySelected * item.price),
            building: item?.building?.name,
            frame: item?.frame,
          };
        });
      tableData.push(...dayData);
      return tableData;
    };

    const table = createTableData();
    setDataTable(table);
  }, [data, daySelected]);

  const buildingName = data
    .map((item) => item.building.name)
    .filter((value, index, self) => self.indexOf(value) === index)
    .join(", ");

  const totalAfterDiscount = useMemo(() => {
    if(amount) return sliceDecimalTwo(substract(amount, discount));
    return 0;
  }, [amount, discount]);

  return (
    <Wrapper>
      {isLoading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
              fullscreen
            />
          }
        />
      )}
      <div className="breadcrumb">
        <span
          onClick={() => navigate("/advertise?tab=place")}
          className="click"
        >
          {`Advertise / `}
        </span>
        <span className="active">{advertisement_name || ""}</span>
      </div>

      <h1 className="title">{advertisement_name || ""}</h1>
      <div className="custom-frame">
        <div className="position-frame">
          <img src={Images.TABLE_FRAME_3} alt="" className="frame" />
          <img
            src={Images.LIGHT_DOT}
            alt=""
            className="light-dot light-dot-1"
          />
          <img
            src={Images.LIGHT_DOT}
            alt=""
            className="light-dot light-dot-2"
          />
          <img
            src={Images.LIGHT_DOT}
            alt=""
            className="light-dot light-dot-3"
          />
          <img
            src={Images.LIGHT_DOT}
            alt=""
            className="light-dot light-dot-4"
          />
        </div>
        <TableContent>
          <AdvertiseTable />
          <div
            className="text-center"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            Confirmation
          </div>
          <Total>
            <div>
              <div className="total-amount border padding height-auto gap-10 w-600">
                <div className="amount-item">
                  <span className="amount-title color-gray">
                    Building :
                  </span>
                  <Tooltip
                    placement="top"
                    color="#051847"
                    overlayInnerStyle={{
                      border: "1px solid #156DF280",
                      borderRadius: "10px",
                      width: "fit-content",
                    }}
                    title={buildingName}
                    autoAdjustOverflow={true}
                    destroyTooltipOnHide={true}
                    zIndex={999}
                  >
                    <span
                      className="amount-value split-name"
                    >
                      {buildingName}
                    </span>
                  </Tooltip>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray">
                    Advertisement :
                  </span>
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "360px",
                    }}
                    className="amount-value split-name"
                  >
                    {advertisement_name}
                  </span>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray">
                    Advertisement Type :
                  </span>
                  <span className="amount-value">
                    {data[0]?.advertisement_frame?.type}
                  </span>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray">
                    Start date :
                  </span>
                  <span className="amount-value">
                    {moment(data[0]?.start_time).format("MM-DD-YYYY")}
                  </span>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray">
                    End date :
                  </span>
                  <span className="amount-value">
                    {moment(data[0]?.end_time).format("MM-DD-YYYY")}
                  </span>
                </div>
                <div className="amount-item">
                  <span className="amount-title color-gray">Days :</span>
                  <span className="amount-value">{daySelected}</span>
                </div>
              </div>
            </div>
            <div className="total-amount gap-5" style={{ flexGrow: "1" }}>
              <div
                className="total-amount box-table"
                style={{ maxHeight: "121px", overflow: "auto"}}
              >
                <table className="scroll">
                  <thead>
                    <tr>
                      <th className="color-gray">Building</th>
                      <th className="color-gray">Frame</th>
                      <th className="color-gray">Time Slot</th>
                      <th className="color-gray">Quantity</th>
                      <th className="color-gray">Price (MCC Point)</th>
                      <th className="color-gray">Amount (MCC Point)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTable.length > 0 ? (
                      <>
                        {dataTable.map((item, index) => {
                          return (
                            <tr key={index}>
                              <Tooltip
                                placement="top"
                                color="#051847"
                                overlayInnerStyle={{
                                  border: "1px solid #156DF280",
                                  borderRadius: "10px",
                                  width: "fit-content",
                                }}
                                title={item.building}
                                autoAdjustOverflow={true}
                                destroyTooltipOnHide={true}
                                zIndex={11}
                              >
                                <td
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: '100px'
                                  }}
                                >
                                  {item.building}
                                </td>
                              </Tooltip>
                              <td >{item.frame.charAt(0).toUpperCase() + item.frame.slice(1).toLowerCase()}</td>
                              <td>{item.time_slot}</td>
                              <td>{item.quantity}</td>
                              <td>{item.price}</td>
                              <td>{item.amount}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <tr></tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="total-amount border padding gap-5 total" style={{ marginTop: '10px' }}>
                <div className="amount-item justify-space">
                  <span className="amount-title">
                    Total amount (before discount) :
                  </span>
                  <span className="amount-value">{`${oneDecimalPlace(
                    amount
                  )} MCC Point`}</span>
                </div>
                <div className="amount-item justify-space">
                  <span className="amount-title">
                    Discount ({discount_percent}%) :
                  </span>
                  <span className="amount-value">{`${oneDecimalPlace(
                    discount
                  )} MCC Point`}</span>
                </div>
                <div className="amount-item justify-space">
                  <span className="amount-title">
                    Total amount (after discount) :
                  </span>
                  <span className="amount-value">{`${oneDecimalPlace(
                    substract(amount, discount)
                  )} MCC Point`}</span>
                </div>
                <div className="amount-item justify-space">
                  <span className="amount-title">Transaction fee ({fee_percent}%) :</span>
                  <span className="amount-value">{`${oneDecimalPlace(
                    fee
                  )} MCC Point `}</span>
                </div>
                <div className="amount-item justify-space">
                  <span className="yellow ">Total due :</span>
                  <span className="yellow">{`${oneDecimalPlace(fixAdditionErrorJavascript(totalAfterDiscount, fee, 1000))} MCC Point`}</span>
                </div>
              </div>
            </div>
          </Total>
        </TableContent>
      </div>
    </Wrapper>
  );
};

export default AdvertiseDetail;
