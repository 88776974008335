import styled from 'styled-components';

export const TabInfoWrapper = styled.div`
margin-left: 20px;
  .title {
    font-size: 16px;
    padding-left: 10px;
    border-left: 5px solid #1e90ff;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .custom-frame {
    width: 100%;
    height: 619px;
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-bottom: 50px;
    position: relative;
  }

  .position-frame {
    position: absolute;
    width: 100%;
    height: 100%;

    @media (max-width: 1280px) {
      display: none;
    }

    .frame {
      width: 100%;
      height: 100%;
    }

    .light-dot {
      position: absolute;
      z-index: 10;
      width: 50px;
      height: 50px;
      mix-blend-mode: color-dodge;

      &.light-dot-1 {
        top: -5px;
        right: -6px;
      }

      &.light-dot-2 {
        top: -23px;
        right: 15px;
      }

      &.light-dot-3 {
        bottom: -23px;
        left: -5px;
      }
    }
  }

  .custom-frame-div {
    position: absolute;
    width: calc(100% - 100px);
    padding-top: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .detail-modal {
    padding: 50px;
    .detail-table {
    }
  }

  .btn-action {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 8px;

    &.detail {
      background-color: #27a376;
    }

    &.delete {
      background-color: #e03137;
    }
  }
`;

export const FilterWrapper = styled.div`
  margin-bottom: 20px;
  /* flex-wrap: wrap; */
  gap: 16px;
  align-items: flex-start;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }

  .select {
    width: 100%;
    background: #00000036;
    height: 40px;
    border-color: #156df2;
    color: #fff;

    .ant-select-selector {
      background-color: unset;
      //background: #051847;
      height: 40px;
      border-color: #156df2;
      color: #fff;
      /* display: flex; */
      align-items: center;
    }

    .ant-select-clear {
      background: transparent;
      margin-right: 25px;
    }

    &.h-56 {
      height: 56px;

      .ant-select-selector {
        height: 56px;
      }
    }
  }

  .date-picker {
    background: #00000036;
    border-color: #156df2;
    height: 40px;

    &.h-56 {
      height: 56px;
    }

    input {
      color: #fff;
    }

    .ant-picker-clear {
      background: transparent;
      margin-right: 30px;
    }
  }

  .form-group {
    display: flex;
    width: calc((100% - 140px)/5);
    flex-direction: column;
    min-width: 120px;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .btn-search{
    margin-top: auto;
  }

`;
