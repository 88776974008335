import styled from 'styled-components';
import { Images } from '../../theme';

export const ChatBotStyled = styled.div`
  position: fixed;
  bottom: 45px;
  right: 100px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #0168ff;
  z-index: 1000;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid #fff;
    transform: scale(0.9);
  }
  &::after {
    position: absolute;
    content: '';
    
    transform: scale(0.5);
    background: url(${props => props.isOpen ? Images.ICON_CLOSE : Images.MESSAGE_ICON});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 70px;
    height: 70px;
  }

  @media (max-width: 575px) {
    right: 24px;
  }
`;
export const ChatBoxStyled = styled.div`
  position: fixed;
  bottom: 130px;
  right: 100px;
  width: 356px;
  height: 516px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  /* cursor: pointer; */
  z-index: 1000;

  .input-chatbox::placeholder {
    font-weight: 500;
  }

  @media (max-width: 575px) {
    right: 24px;
    width: calc(100% - 24px - 20px);
  }
`;