import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #156DF2;
  max-height: 400px;

  @media (max-width: 576px) {
    max-height: 300px;
  }

  .box-table {
    display: flex;
    overflow: auto;
    flex-direction: column;
    height: 100%;
    flex: 1;

    &.h-337 {
      max-height: 337px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #2b6ae680;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #2b6ae680;
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .table-advertise {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .no-data {
    margin: 30px 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ant-empty-description {
      color: #fff;
    }
  }
`;

export const TooltipWrapper = styled.div`
  padding: 10px;

  .row {
    display: flex;
    justify-content: space-between;

    .name {
      white-space: nowrap;
      margin-left: 10px;
    }

    .title {
      margin-right: 4px;
      color: #A8AEBA;
    }

    .price {
      color: #fda000;
    }

    .sold {
      color: #f12222;
    }

    .available {
      color: #73d13d;
    }

    .type {
      text-transform: capitalize;
    }

    .btn-view {
    }
  }
`;

export const FooterAdvertiseWrapper = styled.div`
  margin-top: auto;
  padding-bottom: 20px;
  .list-box {
    list-style-type: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0px;

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 100;

      .box {
        width: 14px;
        height: 14px;
        border: 1px solid #5fa9ff;

        &.sold {
          background-color: #ffba43;
          border: 1px solid #ffe2af;
        }

        &.bought {
          background-color: #66db66;
          border: 1px solid #abffa9;
        }

        &.selected {
          background-color: #f759ab;
          border: 1px solid #ffadd2;
        }
      }
    }
  }

  .box-group {
    margin-top: 10px;
    margin-left: auto;
    display: flex;
    gap: 20px;

    .box-hour {
      border: 1px solid #156df2;
      background: #156df236;
      padding: 6px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;

      .hour,
      .amount {
        font-size: 18px;
        font-weight: 700;
        margin-left: 10px;
      }

      .amount {
        color: #fda000;
      }
    }
  }
`;

export const TBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;


export const Buildings = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 300px;
  min-width: 300px; */
  height: 100%;
`;

export const Building = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  padding: 5px;
  font-size: 14px;
  align-items: center;
  text-transform: capitalize;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
`;

export const BuildingHead = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #156df280;
  text-overflow: ellipsis;
  padding: 5px;
  font-size: 14px;
`;


export const AdsFrames = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100%;
  border-left: 1px solid #156df280;
  border-right: 1px solid #156df280;
`;

export const AdsFrame = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  text-overflow: ellipsis;
  padding: 5px;
  font-size: 14px;
  align-items: center;
`;
export const AdsFrameHead = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #156df280;
  text-overflow: ellipsis;
  padding: 5px;
  font-size: 14px;
  align-items: center;
`;

export const AdsFrameHours = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AdsFrameHour = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const FrameHourItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 40px;

  .frame-chosen {
    border: 1px solid #5fa9ff;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    &.frame-used {
      background: #66db66;
      border: 1px solid #abffa9;
      box-shadow: 0px 0px 10px #66db66, 0px 0px 10px rgba(102, 219, 102, 0.5),
        0px 0px 10px #66db66;

      &:hover {
        background: #66db66;
        border: 1px solid #abffa9;
        box-shadow: 0px 0px 10px #66db66,
          0px 0px 10px rgba(102, 219, 102, 0.5), 0px 0px 10px #66db66;
      }
    }

    &:hover {
      background: #156df2;
      border: 1px solid #b5d3ff;
      box-shadow: 0px 0px 10px #156df2, 0px 0px 10px rgba(21, 109, 242, 0.5),
        0px 0px 10px #156df2;
    }
  }
`;

export const Hours = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #156df280;
`;
export const Hour = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 40px;

  .color-yellow {
    background: linear-gradient(yellow, yellow), white;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* padding: 5px; */
    display: inline;
    font-size: 13px;

    text-shadow: 0px 0px 10px #fa8c16, 0px 0px 10px rgba(250, 140, 22, 0.5),
      0px 0px 10px #fa8c16;
  }
`;
