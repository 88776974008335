import { Drawer } from "antd";
import styled from "styled-components";

export const SidebarWrapper = styled.div`
  width: 300px;
  min-height: calc(100vh - 92px);
  border-right: 1px solid rgba(43, 106, 230, 0.5);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  padding: 16px;

  .sub-menu {
    display: flex;
    justify-content: flex-start;
    color: #fff;
    font-weight: 600;
    padding: 17px 12px;

    .icon-down {
      margin-left: auto;

      &.up {
        transform: rotate(180deg);
      }
    }

    span {
      margin-left: 10px;
    }
  }
  ul {
    color: #757b8c;
    list-style-type: none;
    padding: 0;

    .border {
      border-left: 5px solid #1e90ff;
      border-top: 2.5px solid transparent;
      border-bottom: 2.5px solid transparent;
      height: auto;
    }

    li {
      padding: 17px 0px 17px 40px;
      width: 100%;

      a {
        color: #757b8c;

        &:hover {
          color: #757b8c;
        }
      }
      .disabled {
        pointer-events: none;
      }
    }

    .activated {
      background: rgba(21, 109, 242, 0.21);
      border: 1.5px solid #156df2;

      a {
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
    }
  }
`;

export const SidebarMobileWrapper = styled(Drawer)`
  .ant-drawer-content {
    width: 80%;
    background-color: #051847;

    .ant-drawer-header {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
          img {
            width: 80px;
          }
        }

        .icon-close {
          color: #fff;
        }
      }
    }

    .ant-drawer-body {
      padding: 16px;

      .menu {
        width: 100%;
        padding: 0;
        border: none;

        ul {
          font-weight: 500;

          li {
            padding: 6px 8px 6px 48px;
          }

          .border {
            border: none;
          }

          .activated {
            border: none;
            background: none;
          }
        }
      }
    }
  }
`;
