import styled from 'styled-components';
import { Images } from '../../theme';

export const Container = styled.div`
  border: 1px solid rgba(43, 106, 230, 1);
  padding: 20px;
  .advertise-tab {
    margin: 20px 0;
    margin-left: 20px;
    .ant-tabs-nav-list {
      display: flex;
      gap: 20px;
    }

    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        background-image: url(${Images.TAB_UN_ACTIVE});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 130px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #a8aeba;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        background-image: url(${Images.TAB_ACTIVE});
        color: #fff;
      }
    }
  }
  .advertise-column {
    display: flex;
    flex: 1;
  }
`;


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    font-size: 16px;
    padding-left: 10px;
    border-left: 5px solid #1e90ff;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .custom-frame {
    width: 100%;
    height: 619px;
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-bottom: 50px;
    position: relative;
  }

  .position-frame {
    position: absolute;
    width: 100%;
    height: 100%;

    @media (max-width: 1280px) {
      display: none;
    }

    .frame {
      width: 100%;
      height: 100%;
    }

    .light-dot {
      position: absolute;
      z-index: 10;
      width: 50px;
      height: 50px;
      mix-blend-mode: color-dodge;

      &.light-dot-1 {
        top: -5px;
        right: -6px;
      }

      &.light-dot-2 {
        top: -23px;
        right: 15px;
      }

      &.light-dot-3 {
        bottom: -23px;
        left: -5px;
      }
    }
  }

  .custom-frame-div {
    position: absolute;
    width: calc(100% - 100px);
    padding-top: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .detail-modal {
    padding: 50px;
    .detail-table {
    }
  }

  .btn-action {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 8px;

    &.detail {
      background-color: #27a376;
    }

    &.delete {
      background-color: #e03137;
    }
  }

`;
