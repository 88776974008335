import styled from 'styled-components';

export const TabInfoWrapper = styled.div`
  .advertising {
    margin-top: 50px;

    .title {
      font-size: 16px;
      padding-left: 10px;
      border-left: 5px solid #1e90ff;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      height: 36px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      margin-left: 20px;
    }
  }

  .custom-frame {
    width: 100%;
    height: 619px;
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-bottom: 50px;
    position: relative;
  }

  .position-frame {
    position: absolute;
    width: 100%;
    height: 100%;

    @media (max-width: 1280px) {
      display: none;
    }

    .frame {
      width: 100%;
      height: 100%;
    }

    .light-dot {
      position: absolute;
      z-index: 10;
      width: 50px;
      height: 50px;
      mix-blend-mode: color-dodge;

      &.light-dot-1 {
        top: -5px;
        right: -6px;
      }

      &.light-dot-2 {
        top: -23px;
        right: 15px;
      }

      &.light-dot-3 {
        bottom: -23px;
        left: -5px;
      }
    }
  }

  .custom-frame-div {
    position: absolute;
    width: calc(100% - 100px);
    padding-top: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
      width: calc(100% - 50px);
    }
  }

  .detail-modal {
    padding: 50px;
    .detail-table {
    }
  }
`;
